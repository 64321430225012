
/* ---> BACGROUND COLOR <--- */
.bg-white{background-color: var(--white)}
.bg-red{background-color: var(--red)}
.bg-green{background-color: var(--green)}
.bg-black{background-color: var(--black)}
.bg-gray{background-color: var(--gray)}
.bg-gray-light-1{background-color: var(--gray-light-1)}
.bg-gray-light-2{background-color: var(--gray-light-2)}


/* ---> TEXT COLOR <--- */
.text-white{color: var(--white)}
.text-red{color: var(--red)}
.text-green{color: var(--green)}
.text-black{color: var(--black)}
.text-gray{color: var(--gray)}
.text-gray-dark-1{color: var(--gray-dark-1)}
.text-gray-light-1{color: var(--gray-light-1)}
.text-gray-light-2{color: var(--gray-light-2)}



.gray-scale-link{ filter:grayscale(100%); }
.gray-scale-link:hover{ filter:grayscale(0%); }


/* ---> SOCIAL MEDIAS <--- */

/* ---> Facebook <--- */
.facebook-bg-color{ background-color: #3B5998; }
.facebook-bg-hover-color:hover {
  background-color: #EBF1FF;
  border-top: 3px solid #3B5998 !important;
}

/* ---> x <--- */
.x-bg-color{ background-color: #000; }
.x-bg-hover-color:hover {
  background-color: #D6D6D6;
  border-top: 3px solid #000 !important;
}

/* ---> instagram <--- */
.instagram-bg-color{ background-color: #C565BF; }
.instagram-bg-hover-color:hover {
  background-color: #FFF1FE;
  border-top: 3px solid #C565BF !important;
}

/* ---> youtube <--- */
.youtube-bg-color{ background-color: #D60A00; }
.youtube-bg-hover-color:hover {
  background-color: #FFEDEC;
  border-top: 3px solid #D60A00 !important;
}



/* ---> MARGIN <--- */
.m-5{margin: 5px;}
.m-10{margin: 10px;}
.m-15{margin: 15px;}
.m-20{margin: 20px;}
.m-25{margin: 25px;}
.m-30{margin: 30px;}

.mt-5{margin-top: 5px;}
.mt-10{margin-top: 10px;}
.mt-15{margin-top: 15px;}
.mt-20{margin-top: 20px;}
.mt-25{margin-top: 25px;}
.mt-30{margin-top: 30px;}
.mt-40{margin-top: 40px;}
.mt-50{margin-top: 50px;}
.mt-60{margin-top: 60px;}
.mt-70{margin-top: 70px;}
.mt-80{margin-top: 80px;}
.mt-90{margin-top: 90px;}
.mt-100{margin-top: 100px;}

.ml-5{margin-left: 5px;}
.ml-10{margin-left: 10px;}
.ml-15{margin-left: 15px;}
.ml-20{margin-left: 20px;}
.ml-25{margin-left: 25px;}
.ml-30{margin-left: 30px;}
.ml-y5{margin-left: 5%;}

.mr-5{margin-right: 5px;}
.mr-10{margin-right: 10px;}
.mr-15{margin-right: 15px;}
.mr-20{margin-right: 20px;}
.mr-25{margin-right: 25px;}
.mr-30{margin-right: 30px;}
.mr-y5{margin-right: 5%;}

.mb-5{margin-bottom: 5px;}
.mb-10{margin-bottom: 10px;}
.mb-15{margin-bottom: 15px;}
.mb-20{margin-bottom: 20px;}
.mb-25{margin-bottom: 25px;}
.mb-30{margin-bottom: 30px;}
.mb-40{margin-bottom: 40px;}
.mb-50{margin-bottom: 50px;}
.mb-60{margin-bottom: 60px;}
.mb-70{margin-bottom: 70px;}
.mb-80{margin-bottom: 80px;}
.mb-90{margin-bottom: 90px;}
.mb-100{margin-bottom: 100px;}

/* ---> PADDING <--- */
.p-5{padding: 5px;}
.p-6{padding: 6px;}
.p-7{padding: 7px;}
.p-8{padding: 8px;}
.p-9{padding: 9px;}
.p-10{padding: 10px;}
.p-15{padding: 15px;}
.p-20{padding: 20px;}
.p-25{padding: 25px;}
.p-30{padding: 30px;}

.pt-5{padding-top: 5px;}
.pt-10{padding-top: 10px;}
.pt-15{padding-top: 15px;}
.pt-20{padding-top: 20px;}
.pt-25{padding-top: 25px;}
.pt-30{padding-top: 30px;}
.pt-40{padding-top: 40px;}
.pt-50{padding-top: 50px;}
.pt-60{padding-top: 60px;}
.pt-70{padding-top: 70px;}
.pt-80{padding-top: 80px;}
.pt-90{padding-top: 90px;}
.pt-100{padding-top: 100px;}

.pl-5{padding-left: 5px;}
.pl-10{padding-left: 10px;}
.pl-15{padding-left: 15px;}
.pl-20{padding-left: 20px;}
.pl-25{padding-left: 25px;}
.pl-30{padding-left: 30px;}
.pl-40{padding-left: 40px;}
.pl-50{padding-left: 50px;}
.pl-y5{padding-left: 5%;}

.pr-5{padding-right: 5px;}
.pr-10{padding-right: 10px;}
.pr-15{padding-right: 15px;}
.pr-20{padding-right: 20px;}
.pr-25{padding-right: 25px;}
.pr-30{padding-right: 30px;}
.pr-40{padding-right: 40px;}
.pr-50{padding-right: 50px;}

.pb-5{padding-bottom: 5px;}
.pb-10{padding-bottom: 10px;}
.pb-15{padding-bottom: 15px;}
.pb-20{padding-bottom: 20px;}
.pb-25{padding-bottom: 25px;}
.pb-30{padding-bottom: 30px;}
.pb-40{padding-bottom: 40px;}
.pb-50{padding-bottom: 50px;}
.pb-60{padding-bottom: 60px;}
.pb-70{padding-bottom: 70px;}
.pb-80{padding-bottom: 80px;}
.pb-90{padding-bottom: 90px;}
.pb-100{padding-bottom: 100px;}
.pb-200{padding-bottom: 200px;}
.pb-300{padding-bottom: 300px;}
.pb-400{padding-bottom: 400px;}


/* ---> FONT SIZE <--- */
.font-size-10{font-size: 10px}
.font-size-11{font-size: 11px}
.font-size-12{font-size: 12px}
.font-size-13{font-size: 13px}
.font-size-14{font-size: 14px}
.font-size-16{font-size: 16px}
.font-size-18{font-size: 18px}
.font-size-20{font-size: 20px}
.font-size-22{font-size: 22px}
.font-size-24{font-size: 24px}
.font-size-26{font-size: 26px}
.font-size-28{font-size: 28px}
.font-size-30{font-size: 30px}
.font-size-32{font-size: 32px}
.font-size-34{font-size: 34px}
.font-size-36{font-size: 36px}
.font-size-40{font-size: 40px}
.font-size-45{font-size: 45px}
.font-size-50{font-size: 50px}
.font-size-55{font-size: 55px}
.font-size-60{font-size: 60px}
.font-size-70{font-size: 70px}
.font-size-80{font-size: 80px}
.font-size-90{font-size: 90px}
.font-size-95{font-size: 95px}
.font-size-100{font-size: 100px}
.font-size-110{font-size: 110px}
.font-size-120{font-size: 120px}


/* ---> FONT STYLES <--- */
.font-bold{ font-weight: bold; }
.font-normal{ font-weight: normal; }
.font-normal-200{ font-weight: 200; }
.font-normal-400{ font-weight: 400; }
.font-extra-normal{ font-weight: 100; }
.font-italic{ font-style: italic; }


/* ---> LINE HEIGHT <--- */
.line-height-14{ line-height: 14px; }
.line-height-16{ line-height: 16px; }
.line-height-18{ line-height: 18px; }
.line-height-20{ line-height: 20px; }
.line-height-22{ line-height: 22px; }
.line-height-24{ line-height: 24px; }
.line-height-26{ line-height: 26px; }
.line-height-30{ line-height: 30px; }
.line-height-40{ line-height: 40px; }
.line-height-50{ line-height: 50px; }
.line-height-60{ line-height: 60px; }
.line-height-70{ line-height: 70px; }
.line-height-80{ line-height: 80px; }
.line-height-85{ line-height: 85px; }
.line-height-90{ line-height: 90px; }
.line-height-95{ line-height: 95px; }
.line-height-100{ line-height: 100px; }



/* ---> BORDERS <--- */
.border-top-2{ border-top:2px solid transparent; }
.border-top-3{ border-top:3px solid transparent; }


/* ---> CONTAINERS <--- */
.container-auto{ width:auto; height: auto; }
.container-25{ width:25%; height: auto; }
.container-30{ width:30%; height: auto; }
.container-40{ width:40%; height: auto; }
.container-45{ width:45%; height: auto; }
.container-50{ width:50%; height: auto; }
.container-60{ width:60%; height: auto; }
.container-65{ width:65%; height: auto; }
.container-70{ width:70%; height: auto; }
.container-80{ width:80%; height: auto; }
.container-90{ width:90%; height: auto; }
.container-100{ width:100%; height: auto; }


.width-100{ width: 100%; height: auto; }
.width-90{ width: 90%; height: auto; }
.width-80{ width: 80%; height: auto; }
.width-70{ width: 70%; height: auto; }
.width-60{ width: 60%; height: auto; }
.width-50{ width: 50%; height: auto; }
.width-40{ width: 40%; height: auto; }
.width-45{ width: 45%; height: auto; }
.width-46{ width: 46%; height: auto; }
.width-30{ width: 30%; height: auto; }
.width-25{ width: 25%; height: auto; }
.width-20{ width: 20%; height: auto; }
.height-100{ height: 100%; }


.overflow-auto{ overflow: auto; }
.overflow-hidden{ overflow: hidden; }
.overflow-scroll{ overflow: scroll; }
.overflow-x-scroll{ overflow-x: scroll; }
.overflow-y-scroll{ overflow-y: scroll; }


.max-container-200{max-width: 200px;}
.max-container-250{max-width: 250px;}
.max-container-300{max-width: 300px;}
.max-container-350{max-width: 350px;}
.max-container-400{max-width: 400px;}
.max-container-500{max-width: 500px;}
.max-container-600{max-width: 600px;}
.max-container-700{max-width: 700px;}
.max-container-800{max-width: 800px;}
.max-container-900{max-width: 900px;}
.max-container-1000{max-width: 1000px;}
.max-container-1100{max-width: 1100px;}
.max-container-1200{max-width: 1200px;}
.max-container-1300{max-width: 1300px;}
.max-container-1400{max-width: 1400px;}
.max-container-1500{max-width: 1500px;}


.max-width-200{max-width: 200px;}
.max-width-250{max-width: 250px;}
.max-width-300{max-width: 300px;}
.max-width-350{max-width: 350px;}
.max-width-400{max-width: 400px;}
.max-width-500{max-width: 500px;}
.max-width-600{max-width: 600px;}
.max-width-700{max-width: 700px;}
.max-width-800{max-width: 800px;}
.max-width-900{max-width: 900px;}
.max-width-1000{max-width: 1000px;}
.max-width-1100{max-width: 1100px;}
.max-width-1200{max-width: 1200px;}
.max-width-1300{max-width: 1300px;}
.max-width-1400{max-width: 1400px;}
.max-width-1500{max-width: 1500px;}


.max-height-200{max-height: 200px;}
.max-height-250{max-height: 250px;}
.max-height-300{max-height: 300px;}
.max-height-350{max-height: 350px;}
.max-height-400{max-height: 400px;}
.max-height-450{max-height: 450px;}
.max-height-500{max-height: 500px;}
.max-height-550{max-height: 550px;}
.max-height-600{max-height: 600px;}
.max-height-650{max-height: 650px;}
.max-height-700{max-height: 700px;}


.position-all-0{ position: absolute; top: 0px; bottom:0px; right:0px; left:0px; }
.position-top-left-0{ position: absolute; top: 0px; left:0px; }
.position-top-right-0{ position: absolute; top: 0px; right:0px; }



/* ---> POSITIONS <--- */
/* ---> Sticky <--- */

.sticky-top-0{ position: sticky; top: 0px; }
.sticky-top-y5{ position: sticky; top: 5%; }
.sticky-top-y10{ position: sticky; top: 10%; }


/* ---> FLEX <--- */
.display-block{ display: block; }
.display-flex{ display: flex; }
.flex-column{ flex-direction: column; }
.flex-row{ flex-direction: row; }

.flex-wrap{flex-wrap: wrap;}

.justify-content-left{justify-content: flex-start;}
.justify-content-right{justify-content: flex-end;}
.justify-content-center{justify-content: center;}
.justify-content-around{justify-content: space-around;}
.justify-content-evenly{justify-content: space-evenly;}
.justify-content-between{justify-content: space-between;;}

.align-items-top{align-items: flex-start;}
.align-items-bottom{align-items: flex-end;}
.align-items-center{align-items: center;}
.align-items-stretch{align-items: stretch;}


/* ---> POSITION <--- */
.position-relative{position: relative !important;}
.position-absolute{position: absolute;}
.position-fixed{position: fixed;}
.z-index-1{z-index: 1;}
.z-index-2{z-index: 2;}
.z-index-x1{z-index: 9;}
.z-index-x2{z-index: 99;}
.z-index-x3{z-index: 999;}
.z-index-x4{z-index: 9999;}
.z-index-x5{z-index: 99999;}
.z-index-x6{z-index: 999999;}


/* ---> BORDERS <--- */
.border-all-1{ border:1px solid; }
.border-all-2{ border:2px solid; }
.border-all-3{ border:3px solid; }
.border-all-4{ border:4px solid; }
.border-all-5{ border:5px solid; }
.border-none{ border:none; }

.border-top{ border-top:1px solid; }
.border-bottom{ border-bottom:1px solid; }

.border-color-gray{border-color: var(--gray-light-1); }
.border-color-gray-dark{border-color: var(--gray-dark-2); }
.border-color-dark{border-color: var(--gray-light-3); }



/* ---> RADIUS <--- */
.radius-all-5{border-radius: 5px}
.radius-all-6{border-radius: 6px}
.radius-all-7{border-radius: 7px}
.radius-all-8{border-radius: 8px}
.radius-all-9{border-radius: 9px}
.radius-all-10{border-radius: 10px}
.radius-all-15{border-radius: 15px}
.radius-all-20{border-radius: 20px}
.radius-all-25{border-radius: 25px}

.top-left-radius-5{border-top-left-radius: 5px}
.top-left-radius-10{border-top-left-radius: 10px}
.top-left-radius-15{border-top-left-radius: 15px}
.top-left-radius-20{border-top-left-radius: 20px}
.top-left-radius-25{border-top-left-radius: 25px}

.top-right-radius-5{border-top-right-radius: 5px}
.top-right-radius-10{border-top-right-radius: 10px}
.top-right-radius-15{border-top-right-radius: 15px}
.top-right-radius-20{border-top-right-radius: 20px}
.top-right-radius-25{border-top-right-radius: 25px}

.bottom-left-radius-5{border-bottom-left-radius: 5px}
.bottom-left-radius-10{border-bottom-left-radius: 10px}
.bottom-left-radius-15{border-bottom-left-radius: 15px}
.bottom-left-radius-20{border-bottom-left-radius: 20px}
.bottom-left-radius-25{border-bottom-left-radius: 25px}

.bottom-right-radius-5{border-bottom-right-radius: 5px}
.bottom-right-radius-10{border-bottom-right-radius: 10px}
.bottom-right-radius-15{border-bottom-right-radius: 15px}
.bottom-right-radius-20{border-bottom-right-radius: 20px}
.bottom-right-radius-25{border-bottom-right-radius: 25px}


/* ---> IMG <--- */
.object-fit-cover{ object-fit: cover; }
.object-fit-contain{ object-fit: contain; }
.object-fit-fill{ object-fit: fill; }


/* --> TEXT ALIGN <--- */
.text-left{ text-align: left; }
.text-center{ text-align: center; }
.text-right{ text-align: right; }
.text-justify{ text-align: justify; }


/* FORMS */
::placeholder { color: var(--gray-light-3); }
::-ms-input-placeholder { color: red; }

.form-focus-burgundy:focus{ border:1px solid var(--burgundy); }



/* ---> OTHERS <--- */
.area-center{margin: 0 auto;}
.area-absolute-center{top:0; right:0; left:0; bottom:0; margin: auto;}
.img-size-100{width: 100%; height: auto;}
.clear-both{clear: both;}
