*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



body {

  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root{
  --white:#fff;
  --red: #CE0E2D;
  --green: #00B09B;
  --black :#222;
  --gray: #5D5D5D;
  --gray-dark-1: #B2B2B2;
  --gray-light-1: #f1f1f1;
  --gray-light-2: #e1e1e1;

}

a:active, a:focus, li:focus, li:active, 
button:hover, button:focus {
  outline: none !important;
  border: none !important;        
  text-decoration: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none;

  opacity: 0.4; 
  /*background-color: var(--gray-light-2);*/
  transition: all 0.1s ease-out; 
  outline-width: 0px; 
  outline-color: transparent;
}

