
::-webkit-scrollbar { width:1px; height: 1px; }
::-webkit-scrollbar-track { background-color:#fff; border-left:1px solid #ccc; }
::-webkit-scrollbar-thumb { background-color:#a0a0a0; }
::-webkit-scrollbar-thumb:hover { background-color:#a0a0a0; }
::-moz-selection { background:#a0a0a0; color:#fff; }
::selection { background:#a0a0a0; color:#FFF; }

*{
    text-decoration: none !important;
}

.appBar{ 
    margin-bottom: 20px; 
    padding: 10px;
    position: fixed;
    z-index: 99999;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px #e1e1e1; 
}


.mainMenuIcon,
.LanguageMenuIcon{
    width: 40px;
    height: 40px;
    background-color: var(--white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #d4d4d4;
}


/* Slider */
.sliderContainer{
    width: 100%;
    height: auto;
    margin-top: 15px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px #bbb;
}

.sliderContainer img{
    width: 100%;
    height: auto;
    object-fit: cover;
}


/*Product Categories*/
.productCategoriesItems{
    width: 48.5%;
    height: 200px;
    background-color: #f1f1f1;
    border-radius: 11px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
}

.productCategoriesItems img{ 
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.productCategoriesItemBg{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
}

.productCategoriesItems strong{
    position: absolute;
    z-index: 3;
    color: #fff;
    bottom: 20px;
    left: 20px;
    right: 20px;
    font-size:16px;
    font-weight: bold;
    line-height: 22px;
}


/* Solution Categoeis */
.solutionCategoriesContainer{
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 5px;
    padding-bottom: 20px;
    display: flex;
}

.solutionCategoriesItems{
    width: 120px;
    height: auto;
    background-color: var(--white);
    margin-right: 10px;
    display: inline-block;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px #e1e1e1;
    padding: 5px;
}

.solutionCategoriesItems img{
    width: 70px;
    height: 70px;
    object-fit: contain;
}

/* Bottom Bar Menu */
.bottomBarMenu{
    width: 100%;
    height: 55px;
    background-color: var(--gray-light-1);
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.bottomBarMenu a{ padding: 5px 15px; }
.bottomBarMenu a:focus,
.bottomBarMenu a:active{ background-color: #e1e1e1; }

.bottomBarMenuLine{
    width: 1px;
    height: 100%;
    background-color: #D1D1D1;
}

.bottomBarMenuLine:last-child{
    display: none;
}


/* Main video */
.videoContainer{
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 10px;
}

.videoItems{
    width: 45%;
    min-width: 160px;
    height:215px;
    margin-right: 8px;
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 8px;
}

.videoItems img{ 
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.videoItemBg{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background: linear-gradient(0deg, #222, 40%, transparent);
}

.videoItems strong{
    position: absolute;
    z-index: 3;
    color: #fff;
    bottom: 15px;
    left: 15px;
    right: 15px;
    font-size:13px;
    line-height: 16px;
    white-space: initial;
}

.mainVideoPlay{
    position: absolute;
    z-index: 5;
    left: 0;
    right: 0;
    top: 25%;
    margin: auto;
}





/* Main Product List */
.mainProductListContainer{
    padding-bottom: 20px;
    overflow: auto;
    white-space: nowrap;
    display: flex;
}

.mainProductItems{
    width: 30%;
    height: auto;
    background-color: var(--white);
    display: inline-block;
    border-radius: 8px;
    padding: 5px;
    white-space: initial;

}

.mainProductImg{
    position: relative;
    box-shadow: 0px 0px 10px 0px #e1e1e1;
    margin-bottom: 10px;
    border-radius: 10px;
}

.mainProductItems img{
    width: 100px;
    height: 100px;
    object-fit: contain;
    
}

.new-label{
    position: absolute;
    z-index: 2;
    padding: 3px 5px;
    left: 0;
    top: 15%;
    background-color: #C4000B;
    color: #fff;
    font-size: 11px;
    border-radius: 0px 5px 5px 0px;
}



/* Main Blog Posts */

.blogImg{
    width: 106px;
    height: 65px;
    margin-right: 20px;
}

.blogImg img{
    width: 106px;
    height: 70px;
    object-fit: cover;
    border-radius: 10px;
}

.mainBlogLine{
    width: 100%;
    height: 1px;
    background-color: var(--gray-light-2);
    margin: 20px 0px 15px 0px;
}


.mainBlogLine:last-child{ display: none; }





/* Blogs detail */

.blogContent h1,
.blogContent h2,
.blogContent h3,
.blogContent h4,
.blogContent h5,
.blogContent h6,
.blogContent strong
{
    font-size: 20px;
    color: var(--black);
    font-weight: bold;
    margin: 20px 0px !important;
}


.blogContent,
.blogContent > p{
    font-size:14px;
    color: #979797;
    font-weight: normal;
    line-height: 22px;
    margin-bottom: 25px;
}


.blogContent,
.blogContent > ul{
    margin-bottom: 25px;
    list-style-type: disc;
    list-style-position: inside;
}

.blogContent,
.blogContent > ul li{
    font-size:14px;
    color: #979797;
    font-weight: normal;
    line-height: 22px;
    margin-bottom: 4px;
}

.blogContent img{
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 15px;
}

.blogContent a, .blogContent a strong{
    font-weight: bold;
    font-size:16px;
    line-height: 24px;
    color: #2A86FF;
}





/* Drawer Menu */
.drawerMenuContainer{ background-color: var(--red) !important; overflow-y: scroll; }
.drawerMenuContent{ display: flex; flex-direction: column; }
.drawerLogo{ width: 110px; height: auto; margin:20px; }
.drawerMenuLine{
    width: auto;
    height: 1px;
    background-color: #A9001C;
    margin: 5px 20px;
    margin-bottom: 15px;
}

.drawerMenuItems{ padding: 10px 20px; }
.drawerMenuItems img{ width: 37px; height: auto; margin-right: 15px; }

.drawerMenuItems:focus,
.drawerMenuItems:active{
    background-color: #A2001B;
}

.drawerMenuListLine{
    width: auto;
    height: 1px;
    background-color: #DB0024;
    margin: 0px 20px;
}

.drawerMenuListLine:last-child{ display: none; }


/* Drawer Menu */
.drawerLanguageContainer{ background-color: var(--green) !important; overflow-y: scroll; }
.drawerLanguageContent{ display: flex; flex-direction: column; }
.drawerLanguageLine{
    width: auto;
    height: 1px;
    background-color: #009A87;
    margin: 5px 20px;
    margin-bottom: 15px;
}

.drawerLanguageItems{ padding: 10px 20px; }
.drawerLanguageItems img{ width: 37px; height: auto; margin-right: 15px; }

.drawerLanguageItems:focus,
.drawerLanguageItems:active{
    background-color: #00A28F;
}

.drawerLanguageListLine{
    width: auto;
    height: 1px;
    background-color: #00C7AF;
    margin: 0px 20px;
}

.drawerLanguageShort{
    width: 37px; 
    height: 37px; 
    border-radius: 50%; 
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    color: var(--green);
    font-size: 14px;
    font-weight: bold;
}

.drawerLanguageListLine:last-child{ display: none; }


.backMenu{
    width: 40px;
    height: 40px;
    background-color: var(--white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #d4d4d4;
}

.aboutContainer > .aboutTitle{
    font-size: 22px;
    color: var(--red);
    font-weight: bold;
    display: block;
    margin: 20px 0px !important;
}


.aboutContainer,
.aboutContainer > p{
    font-size:14px;
    color: #979797;
    font-weight: normal;
    line-height: 22px;
    margin-bottom: 25px;
}



.aboutContainer,
.aboutContainer > ul{
    margin-bottom: 25px;
    list-style-type: disc;
    list-style-position: inside;
}

.aboutContainer,
.aboutContainer > ul li{
    font-size:14px;
    color: #979797;
    font-weight: normal;
    line-height: 22px;
    margin-bottom: 4px;
}


.aboutBanner{
    width: 100%;
    height: 210px;
    background-color: #f1f1f1;
    border-radius: 10px;
    overflow: hidden;
}

.aboutBanner img{
    width: 100%;
    height: 210px;
    object-fit: cover;
    background-color: rgb(red, green, blue);
}


/* Product List */
.productItemContainer{
    width: 100%;
    height: 85px;
    background-color: #fff;
    border-radius: 10px;
    /*box-shadow: 0px 0px 7px 0px #E3E3E3;*/
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 0px;
}


.productItems{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.productItems strong{
    font-size: 14px;
    width: 70%;
    line-height: 20px;
    color: var(--black);
    font-weight: bold;
}

.productImg{
    width: 70px;
    height: 70px;
    margin-right: 10px;
    margin-left: 5px;
    display: flex;
}

.productImg img{
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.productItemsArrow{
    width: 25px;
    height: 25px;
    background-color: #f1f1f1;
    color: #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
}


/* Product Detail Image */
.productDetailImg{
    width: 100%;
    height: 320px;
    background-color: #f1f1f1;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 20px;
}

.productDetailImg img{
    width: 100%;
    height: auto;
    object-fit: contain;
}


.productDetailApplicationImg{
    width: 100%;
    height: 320px;
    background-color: #f1f1f1;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

.productDetailApplicationImg img{
    width: 100%;
    height: auto;
    object-fit: cover;
}


.productDocumentTds{
    width: 48px;
    height: 48px;
    background-color: #009A87;
    border-radius: 50%;
    border:4px solid #00D4BA;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
}



.productDocumentSds{
    width: 48px;
    height: 48px;
    background-color: #C00020;
    border-radius: 50%;
    border:4px solid #E6526B;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
}


.documentPreviewFile{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    margin-top: 70px;
    padding-bottom: 50px;
}

.documentRefresh{
    width: auto;
    height: auto;
    border-radius: 50%;
    position: fixed;
    z-index: 1;
    bottom: 70px;
    right: 20px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.documentAlert{
    height: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}



.whatsAppIcon{
    width: 42px;
    height: 42px;
    background-color: #25D366;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #fff;
    font-weight: bold;
}


.productDescription,
.productDescription p{
    width: 100%;
    height: auto;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
    font-weight: normal;
    color: var(--black);
}

.ProductProperties-ApplicationAreas,
.ProductProperties-ApplicationAreas ul li{
    font-size:14px;
    font-weight: normal;
    list-style-type: disc;
    list-style-position: outside;
    line-height: 24px;
    margin-left: 20px;
    margin-bottom: 5px;
    color: var(--black);
}

.packagingContainer{
    width: 100%;
    height: auto;
}

.packagingTable{
    width: 100%;
    height: auto;
}

.packagingTable th{
    padding: 10px;
    background-color: var(--green);
    color: var(--white);
    font-weight: bold;
    font-size: 14px;
    text-align: center;
}


.packagingTable tr td{
    padding: 10px;
    color: var(--white);
    font-weight: bold;
    font-size: 12px;
    color: var(--black);
    text-align: center;
}

.packagingTable tr{
    background-color: #fff;
}

.packagingTable tr:nth-child(odd) {
    background-color: #E8F8F6;
}



.productGalleries{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}




.productGalleriesItems{
    width: 30%;
    min-width: 100px;
    height: 110px;
    margin: 3px;
    overflow: hidden;
    border-radius: 10px;
    object-fit: cover;
    border: 1px solid #e1e1e1;
}

.productGalleries img:focus,
.productGalleries img:active{
    opacity: 0.5;
    
}

.productBrochureItems{
    width: 46%;
    min-width: 150px;
    height: auto;
    margin: 5px;
    margin-bottom: 30px;
}

.productBrochureCover{
    width: 100%;
    height: auto;
}

.productBrochureCover img{
   width: 100%;
   height: 140px;
   object-fit: cover;
   border-radius: 10px;
   border:1px solid #BCBCBC;
}

.productBrochureItems strong{
    font-size:14px;
    font-weight: normal;
    color: var(--black);
    display: block;
    line-height: 18px;
}

/* React Modal */
.react-responsive-modal-root {
    z-index: 99999999 !important;
}

.react-responsive-modal-overlay {
    background: rgba(255, 255, 255, 0.9) !important;
}

.react-responsive-modal-modal {
    width: auto !important;
    height: 700px;
    display: flex !important;
    background: transparent !important;
    box-shadow: none !important;
    border:none !important;
    overflow: hidden !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    background-color: transparent !important;
    padding: 5px !important;
    padding-bottom: 20px !important;
    margin: 0px !important;
}


.react-responsive-modal-modal img {
    width: auto !important;
    max-width: 100%;
    height: 90% !important;
    object-fit: contain;
    margin-top: 20%;
}

.react-responsive-modal-closeButton{
    position: absolute !important;
    z-index: 999999999 !important;
}

.modalClose{
    width: auto;
    height: auto;
    position: absolute;
    top: 20px;
    right: 20px;
}

.modalClose:focus,
.modalClose:active{
    opacity: 0.6;
}


.certificatesContainer{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.certificatesItems{
    width: 30%;
    height: auto;
    margin: 5px;
    overflow: hidden;
    border-radius: 10px;
    object-fit: contain;
    border: 1px solid #e1e1e1;
}

.certificatesContainer img:focus,
.certificatesContainer img:active{
    opacity: 0.5;
}


.maps{
    width: 100%;
    height: 205px;
    border: none;
    border-radius: 10px;
    border:2px solid #DEDEDE;
}

.contact-icon{
    width: 45px;
    height: auto;
}

/* Calculator */

.calculatorResultContainer{
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
    text-align: center;
}

.calculatorResultContainer span{
    font-size: 20px;
    color: var(--gray);
    margin: 5px;
    font-weight: bold;
}

.calculatorResultContainer small{
    font-size: 16px;
    color: var(--gray);
}

.calculatorResultContainer strong{
    font-size: 32px;
    color: var(--green);
    margin: 5px;
    font-weight: bold;
}

.calculatorWarningAlert{
    width: 100%;
    height: auto;
    padding: 20px 10px;
    text-align:center;
    background-color: #FFFBBF;
    color: #B2AC51;
    border:1px solid #E6E198;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
}



/* Search */

.searchContainer{
    width: 100%;
    background-color: #E8FFFC;
    margin-bottom: 40px;
    margin-top: -10px;
    box-sizing: border-box !important;
    padding: 10px;
    position: fixed;
}

.searchInput{
    width: 100%;
    height: auto;
    background-image: url("../icons/search-ic.svg");
    background-repeat: no-repeat;
    background-position: center right 15px;
    background-color: #fff;
    border:1px solid var(--green);
    font-size: 16px;
}


